<template>
    <div>
        <div class="news-head-line">
        </div>
        <div class="container-1" style="margin-bottom:10rem">
            <img class="news-img" src="../../assets/image/news/05-3img.png"/>
            <div class="news-box">
                <div class="title">比孚科技和亚马逊云科技联合主办SAP上云探索及经验分享交流会</div>
                <div class="date">2022/11/30</div>
                <div class="line"></div>
                <img class="news-img2" src="../../assets/image/news/5-03img2.png"/>
                <div class="content">2022年11月30日，比孚科技和亚马逊云科技将在上海地标建筑马勒别墅饭店，联合主办SAP上云探索及经验分享交流会。<br/><br/>

                随着数字经济发展日新月异，企业转型面临多重挑战，云转型无疑成为未来发展的趋势，加上新冠疫情的影响，加速了企业上云的决策，是推进企业数字化转型的关键步伐。<br/><br/>

                利用亚马逊云服务的弹性、敏捷性、全球化部署等特点，比孚助力企业SAP上云，实现客户数字化战略的落地以及核心业务效能的提升。<br/><br/>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import store from '@/plugins/store';
import { mapActions } from 'vuex';
export default {
    name: 'BizfocusPagesPurchaseCollaboration',

    data() {
        return {
        };
    },

    mounted() {
        setTimeout(()=>{
            this.refreshIndexInfo({data:'5' , news:true});
        },500)
    },

    methods: {
        ...mapActions([
            'refreshIndexInfo'
        ]),
    },
};
</script>

<style lang="less" scoped>
.news-head-line{
    width: 190rem;
    border: 1px solid #F3FAF6;
    margin-top: 11rem;
    box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.2);
}
.news-img{
    width: 100rem;
    height: 30rem;
    margin-top: 4rem;
}
.news-img2{
    width: 65.4rem;
    height: 22.9rem;
    margin-top: 4rem;
}
.news-box{
    width: 100rem;
    margin: auto;
    margin-top: 6rem; 
    .title{
        height: 2.6rem;
        font-size: 2.6rem;
        font-family: 'CN_Medium';
        font-weight: 500;
        color: #333333;
        line-height: 3.9rem;
        text-align: justify;
    }
    .date{
        height: 1.9rem;
        font-size: 1.6rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #999999;
        line-height: 2.4rem;
        text-align: justify;
        margin-top: 2rem;
    }
    .line{
        width: 100rem;
        height: 1px;
        border: 1px solid #EDEDED;
        margin-top: 1rem;
    }
    .content{
        width: 100.1rem;
        height: 46.7rem;
        font-size: 1.6rem;
        font-family: 'CN_Regular';
        font-weight: 400;
        color: #4F4F4F;
        line-height: 3rem;
        margin-top: 2.4rem;
        text-align: justify;
    }
}
</style>